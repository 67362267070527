<template>
  <div>
    <MainFrame>
      <v-card-title>
        <v-icon class="mr-2" color="primary"> mdi-table </v-icon> {{ title }}
      </v-card-title>
      <v-card-subtitle>
        {{ subtitle }}
      </v-card-subtitle>
      <v-card class="my-6 py-4 px-10">
        <v-card-title>
          <v-icon class="mr-2">mdi-magnify</v-icon> Parametros de busqueda
        </v-card-title>
        <v-card-subtitle>
          Seleccione los parametros a utilizar en la busqueda de
          {{ nameObject.toLowerCase() }}
        </v-card-subtitle>
        <v-card-text>
          <v-form ref="searchForm" v-model="validForm" lazy-validation>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <v-combobox
                  id="input-country"
                  label="País"
                  v-model="searchValues.country"
                  :items="countrys"
                  @keyup.enter="searchData()"
                  :rules="[(v) => !!v || 'El país es requerido']"
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-combobox
                  id="input-seller"
                  label="Seller"
                  v-model="searchValues.seller"
                  :items="sellers"
                  @keyup.enter="searchData()"
                  :rules="[(v) => !!v || 'El seller es requerido']"
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-combobox
                  id="input-consumer"
                  label="Consumer"
                  v-model="searchValues.consumer"
                  :items="consumers"
                  @keyup.enter="searchData()"
                  :rules="[(v) => !!v || 'El consumer es requerido']"
                  outlined
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-4">
          <v-spacer />
          <v-btn color="primary" :disabled="!validForm" @click="searchData()">
            Realizar busqueda
          </v-btn>
        </v-card-actions>
      </v-card>
      <RuleTable
        v-if="items.length > 0"
        :headers.sync="headers"
        :items.sync="items"
        :priorityColor="priorityColor"
        :loading.sync="loading"
        @openForm="openForm"
        @showObject="showObject"
        @disableObject="disableObject"
      />
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="1000"
      >
        <div v-if="formPanel">
          <RuleForm
            :headers="headers"
            :object.sync="object"
            :isEditing="isEditing"
            :currentTimezone="searchValues.country.timezone"
            @UpdateObject="updateObject"
            @SaveObject="saveObject"
            @ClosePanel="closePanel()"
          />
        </div>
        <div v-if="showPanel">
          <ShowPanel
            :headers="headers"
            :object.sync="object"
            :priorityColor="priorityColor"
            @ClosePanel="closePanel()"
          />
        </div>
      </v-dialog>
    </MainFrame>
  </div>
</template>

<script>
import groupsMockup from "@/assets/mockups/groups";
import utils from "@/shared/utils/Maintainers";
import valuesJson from "./json/values.json";
import { endpoints } from "@/environment/environment";

export default {
  mixins: [utils],
  components: {
    MainFrame: () => import("@/layout/MainFrame"),
    RuleTable: () => import("./components/RuleTable"),
    RuleForm: () => import("./components/RuleForm"),
    ShowPanel: () => import("./components/ShowPanel"),
  },
  data: () => ({
    title: "Mantenedor de reglas",
    subtitle: "Mantención y edición de las diferentes reglas",
    nameObject: "Reglas",
    groups: [],
    loading: false,
    defaultRule: {
      attributes: [],
      productConditions: [],
    },
  }),
  mounted() {
    this.typeValues = { ...valuesJson };
    this.object = Object.assign({}, this.defaultRule);
    this.loadComboBox();
  },
  methods: {
    addItemsToGroupName() {
      if (this.headers?.length > 0) {
        this.headers.forEach((header) => {
          if (header.value.toLowerCase().search("groupname") > -1) {
            header.type = "groupcode";
            header.items = groupsMockup.map((group) => {
              return group.ruleGroupName;
            });
          }
        });
      }
    },
    getRules() {
      const params = {
        seller: this.searchValues.seller.value,
        consumer: this.searchValues.consumer.value,
        country: this.searchValues.country.value,
      };
      this.$http
        .get(
          `/${endpoints.admin.path}/${endpoints.admin.controllers.rules.path}?sellerId=${params.seller}&countryId=${params.country}&consumerId=${params.consumer}`
        )
        .then((res) => {
          this.items = [...res.body];
          this.items = this.items.sort((a, b) => a.priority - b.priority);
          this.items = this.items.map((v) => {
            groupsMockup.forEach((group) => {
              if (group.ruleGroupCode === v.ruleGroupCode) {
                v.ruleGroupPriority = group.ruleGroupPriority;
                v.ruleGroupName = group.ruleGroupName;
              }
            });
            return v;
          });
          this.generateHeaders();
          this.getPriorityColors();
          this.addItemsToGroupName();
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getGroups() {
      const params = {
        seller: this.searchValues.seller.value,
        consumer: this.searchValues.consumer.value,
        country: this.searchValues.country.value,
      };
      this.$http
        .get(
          `/${endpoints.admin.path}/${endpoints.admin.controllers.groups.path}?sellerId=${params.seller}&countryId=${params.country}&consumerId=${params.consumer}`
        )
        .then((res) => {
          this.groups = [...res.body];
          this.groups = this.groups.sort((a, b) => a.priority - b.priority);
          this.getRules();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getAll() {
      this.loading = true;
      this.items = [];
      this.groups = [];
      this.getGroups();
    },
    disableObject(v) {
      this.loading = true;
      const onConfirm = () => {
        v.status = !v.statusBool;
        this.updateObject(v);
      };
      const onCancel = () => {
        this.$notification.info("Se ha cancelado la acción");
        this.loading = false;
      };
      this.$alert.warning({
        title: `${v.status ? "Deshabilitar" : "Habilitar"} la regla`,
        text: `¿Estas seguro de querer ${
          v.status ? "deshabilitar" : "habilitar"
        } la regla código '${
          v.ruleCode
        }'?, esta acción afectara únicamente a la regla seleccionada.`,
        confirmText: `Si, ${v.status ? "deshabilitar" : "habilitar"}`,
        cancelText: "No, cancelar",
        onConfirm,
        onCancel,
      });
    },
    updateObject(v) {
      this.dialog = false;
      this.formPanel = false;
      this.loading = true;
      this.groups.forEach((group) => {
        if (group.ruleGroupName === v.ruleGroupName) {
          v.ruleGroupPriority = group.ruleGroupPriority;
          v.ruleGroupCode = group.ruleGroupCode;
        }
      });
      this.$http
        .put(`${endpoints.dlBusiness}/rules/${v.id}`)
        .send(v)
        .then(() => {
          this.$notification.success(
            `Regla ${v.ruleCode} actualizada con exito`
          );
          this.loading = false;
          this.getAll();
        })
        .catch((err) => {
          console.error(err);
          this.$notification.error(`Ha ocurrido un error inesperado`);
          this.loading = false;
        });
    },
    parseDatesInRules(v) {
      v.effectiveDate = this.parseDateStringToISO(v.effectiveDate);
      v.expireDate = this.parseDateStringToISO(v.expireDate);
      return v;
    },
    saveObject(v) {
      const toAdd = {
        consumerId: this.searchValues.consumer.value,
        countryId: this.searchValues.country.value,
        sellerId: this.searchValues.seller.value,
        status: true,
        updateDate: null,
        ruleSystem: false,
        id: "",
        creationDate: this.dayjs.utc().format(),
        clientRuleHistory: null,
        user: {
          dni: "161803398",
          username: "ADMIN",
        },
      };
      this.groups.forEach((group) => {
        if (group.ruleGroupName === v.ruleGroupName) {
          v.ruleGroupPriority = group.ruleGroupPriority;
          v.ruleGroupCode = group.ruleGroupCode;
        }
      });
      v = { ...v, ...toAdd };
      v = this.parseDatesInRules(v);
      this.dialog = false;
      this.formPanel = false;
      this.loading = true;
      this.$http
        .post(`${endpoints.dlBusiness}/rules`)
        .send([v])
        .then(() => {
          this.$notification.success(`Regla ${v.ruleCode} creada con exito`);
          this.loading = false;
          this.getAll();
        })
        .catch((err) => {
          console.error(err);
          this.$notification.error(`Ha ocurrido un error inesperado`);
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
